// test2

<template>
	<v-container fluid ma-0 pa-0>
	<v-card dark tile flat>
		<v-toolbar
			color="blue-grey darken-3"
			dark
			flat tile dense extension-height="35px"
		>

			<v-toolbar-title dense class="font-weight-light">Test2</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-btn small class="green" :disabled="!isDirty" @click="onSubmit">
				save
			</v-btn>

			<template v-slot:extension>
				<v-tabs
					v-model="htab"
					align-with-title centered
					color="grey"
					background-color="transparent"
					slider-color="grey"
                    active-class="blue-grey darken-1 black--text"
                    dark dense height="35px"
					next-icon="mdi-arrow-right-bold-box-outline"
					prev-icon="mdi-arrow-left-bold-box-outline"
					show-arrows show-arrows-on-hover center-active
					@change="htabChanged($event)"
				>
					<v-tab v-for="(item) in items" :key="item.id" :disabled="!item.id">
						{{ item.table_name }}
					</v-tab>
				</v-tabs>
			</template>
		</v-toolbar>
		<v-tabs-items v-model="htab">
			<v-tab-item v-for="(item, hindex) in items" :key="hindex">			
		        <v-tabs v-model="vtab" vertical dense
					background-color="blue-grey darken-3" color="grey" active-class="blue-grey darken-1 black--text">
			        <v-tab v-for="(subitem) in item.cfgreftables" :key="subitem.id" style="height: 35px; font-size: 10px !important;">
				        {{ subitem.reftable }}
			        </v-tab>
                    <v-tab-item v-for="(subitem, vindex) in item.cfgreftables" :key="vindex">
						<DragDrop v-if="htab == hindex && vtab == vindex" :ctx="{ table: item.table_name, ref: subitem.reftable }" @dying="childDying($event)"></DragDrop>
                    </v-tab-item>
                </v-tabs>
			</v-tab-item>
		</v-tabs-items>
	</v-card>
	<v-overlay absolute :value="loading">
		<v-progress-circular indeterminate size="64" width="7" color="cyan darken-4"></v-progress-circular>
	</v-overlay>
	<v-snackbar v-model="snack.active" :color="snack.type"> {{ snack.text }}
		<v-btn text dark @click="snack.active = false">Ok</v-btn>
	</v-snackbar>
	</v-container>
</template>

<script>
	import DragDrop from './DragDrop'
	import { mapGetters } from 'vuex'
	export default {
	  	name: 'test2',
		components: { DragDrop, },
	  	props: {
	  	},
		data() {
			return {
				htab: 0,
				vtab: 0,
				/*items: [ 
                    {id: 0, name: '', },
                    {id: 1, name: 'A', items: [ { id: 2, name: 'A1', }, ], },
                    {id: 3, name: 'B', items: [ { id: 4, name: 'B1', }, { id: 5, name: 'B2', } ], }
                ],*/
				msgError: '',
				isDirty: false,
				loading: false,
				snack: { active: false, type: 'success', text: 'saved',	},
            }
        },
		computed: {
			...mapGetters({
				items: 'test2/getCfgRefTables',
			}),
		},
		methods: {
			fetchData () {
				this.loading = true
				let self = this
				this.$store.dispatch('test2/getCfgRefTables')
				.then((response) => {
					//console.log(this.items)
					self.loading = false
				})
				.catch((error) => {
					console.log(error)
					self.loading = false
				});
			},
			submitData(param) {
				this.loading = true	
				let self = this
				this.$store.dispatch('test2/updateCfgLists', param)
				.then((response) => {
					console.log(response)
					self.loading = false
				})
				.catch((error) => {
					console.log('CfgLists->submit->error')
					console.log(error)
					self.loading = false
				});
			},
			onSubmit() {
				this.isDirty = false            
				this.loading = true
				this.loading = false
				this.snack = {active: true, type: 'success', text: 'saved'}
			},
			htabChanged(e) {
				console.log('htab changed: ' + JSON.stringify(e))
				this.vtab = 0
			},
			childDying(e) {
				console.log('child dying: ' + JSON.stringify(e.ctx))
				this.submitData(e)
			},
        },
		mounted() {
			this.fetchData ()
		},
	  	watch: {},
    }
</script>

<style scoped>
</style>